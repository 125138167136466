<template>
  <div>  
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <b-alert v-model="flagErr" :variant="variant" dismissible>
        {{ errMsg }}
    </b-alert>
    <b-row>
      <b-col lg="8" offset-lg="2">
        Este módulo actualiza el campo servicio restantes de los pedidos de
        acuerdo a la cantidad de servicios finalizados.
      </b-col>     
    </b-row>
    <b-row>
      <b-col lg="8" offset-lg="2">
        <CCard>
          <CCardHeader>
            <strong>Filtros</strong>
            <div class="card-header-actions">
              <b-button @click="update">Actualizar</b-button>
            </div>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col lg="4">
                <v-date-picker 
                mode="range" 
                v-model="selectedDate" 
                is-inline />
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { DatePicker } from "v-calendar";
import moment from "moment";
const today = new Date();
  export default {
    name: "ordersCount",
    components: {
      Loading,
      "v-date-picker": DatePicker,
    },
    data() {
      return {
        flagErr: false,
        loading: false,
        errMsg: '',
        variant: 'success',
        selectedDate: {
          start: new Date(today.getFullYear(), today.getMonth(), 7),
          end: new Date(today.getFullYear(), today.getMonth(), 11)
        },
      }
    },
    methods: {
      update(){
        this.loading = true
        this.flagErr = false
        let dateStart = moment(this.selectedDate.start)
            .format("yyyy-MM-DDT")+'00:00:00.000Z'
        let dateEnd = moment(this.selectedDate.end)
            .format("yyyy-MM-DDT")+'23:59:59.000Z'
        let payload = { dateStart, dateEnd }
        console.log('data: ', payload)
        let updateOrderServicesLeft = this.$firebase.functions().httpsCallable('updateServicesLeftCounter')
        updateOrderServicesLeft(payload).then(res => {
          let data = res.data
          console.log(data)
          this.errMsg = 'Pedido actualizado, campo servicios restantes'
          this.variant = 'success'
          this.flagErr = true
        }).catch(error => {
          this.errMsg = 'No se actualizaron los pedidos'
          this.variant = 'error'
          this.flagErr = true
        }).finally(_=>this.loading = false)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>